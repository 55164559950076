<template>
  <div class="aside">
    <div
      class="box mt_5 cursor-pointer"
      v-for="(item, index) in imgList"
      :key="index"
      @mouseleave="mouseLeave(item)"
      @mouseenter="mouseOver(item)"
      @click="handleToJump(item.type)"
      :class="item.active == true ? 'bg_2C8EFF' : ''"
    >
      <img :src="item.imgShow" :class="item.imgClass" class="mt_20" />
      <div
        class="mt_5 fz_10"
        :class="item.active == true ? 'fc_fff' : 'fc_666'"
      >
        {{ item.label }}
      </div>
    </div>

    <el-popover placement="left" width="120" trigger="hover">
      <div class="pd_code text-center">
        <div class="flex justify-center">
          <img src="../../assets/images/home/wx.png" class="wxlogo_image" />
          <div class="ml_5">客服微信</div>
        </div>
        <img src="../../assets/images/aside/kefu.png" class="code_image" />
        <div class="fz_10 fc_363636 lh_16">微信扫一扫添加</div>
        <div class="fz_10 fc_363636 lh_16">
          或搜索关注：<span class="fc_07C160">xlqiaokeli</span>
        </div>
      </div>
      <div slot="reference">
        <div
          class="box mt_5"
          @mouseleave="wx_active = false"
          @mouseenter="wx_active = true"
          :class="wx_active == true ? 'bg_2C8EFF' : ''"
        >
          <img
            src="@/assets/images/aside/weixin.png"
            class="mt_20 wx_image"
            v-if="wx_active == false"
          />
          <img
            src="@/assets/images/aside/weixin_active.png"
            class="mt_20 wx_image"
            v-else
          />
          <div
            class="mt_5 fz_10"
            :class="wx_active == true ? 'fc_fff' : 'fc_666'"
          >
            联系客服
          </div>
        </div>
      </div>
    </el-popover>

    <div
      class="box mt_5"
      v-if="btnFlag"
      @mouseleave="top_active = false"
      @mouseenter="top_active = true"
      @click="backTop"
      :class="top_active == true ? 'bg_2C8EFF' : ''"
    >
      <img
        src="@/assets/images/aside/top.png"
        class="mt_20 top_image"
        v-if="top_active == false"
      />
      <img
        src="@/assets/images/aside/top_active.png"
        class="mt_20 top_image"
        v-else
      />
      <div class="mt_5 fz_10" :class="top_active == true ? 'fc_fff' : 'fc_666'">
        返回顶部
      </div>
    </div>
  </div>
</template>
<script>
import { enterEnterprise } from '@/utils';
export default {
  data() {
    return {
      wx_active: false,
      top_active: false,
      btnFlag: false,
      imgList: [
        {
          img: require('@/assets/images/aside/company.png'),
          imgShow: require('@/assets/images/aside/company.png'),
          imgActive: require('@/assets/images/aside/company_active.png'),
          imgClass: 'company_image',
          // imgStyle: {
          //   width: '32px',
          //   height: '32px'
          // },
          label: '入驻企业',
          active: false,
          type: 'company'
        },
        {
          img: require('@/assets/images/aside/supply.png'),
          imgShow: require('@/assets/images/aside/supply.png'),
          imgActive: require('@/assets/images/aside/supply_active.png'),
          imgClass: 'company_image',
          // imgStyle: {
          //   width: '32px',
          //   height: '32px'
          // },
          label: '供应商',
          active: false,
          type: 'supply'
        },
        {
          img: require('@/assets/images/aside/school.png'),
          imgShow: require('@/assets/images/aside/school.png'),
          imgActive: require('@/assets/images/aside/school_active.png'),
          imgClass: 'company_image',
          // imgStyle: {
          //   width: '32px',
          //   height: '32px'
          // },
          label: '学校/学院',
          active: false,
          type: 'school'
        },
        {
          img: require('@/assets/images/aside/thirdCompany.png'),
          imgShow: require('@/assets/images/aside/thirdCompany.png'),
          imgActive: require('@/assets/images/aside/thirdCompany_active.png'),
          imgClass: 'company_image',
          // imgStyle: {
          //   width: '32px',
          //   height: '32px'
          // },
          label: '第三方服务',
          active: false,
          type: 'third_company'
        }
        // {
        //   img: require('@/assets/images/aside/weixin.png'),
        //   imgShow: require('@/assets/images/aside/weixin.png'),
        //   imgActive: require('@/assets/images/aside/weixin_active.png'),
        //   imgStyle: {
        //     width: '39px',
        //     height: '32px'
        //   },
        //   label: '联系客服',
        //   active: false
        // },
        // {
        //   img: require('@/assets/images/aside/top.png'),
        //   imgShow: require('@/assets/images/aside/top.png'),
        //   imgActive: require('@/assets/images/aside/top_active.png'),
        //   imgStyle: {
        //     width: '32px',
        //     height: '32px'
        //   },
        //   label: '返回顶部',
        //   active: false
        // }
      ],
      local: ''
    };
  },

  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
    this.local = window.location.host;
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollToTop);
  },
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      that.top_active = false;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    // 移入
    mouseOver(tag) {
      tag.imgShow = tag.imgActive;
      tag.active = true;
    },
    // 移出
    mouseLeave(tag) {
      tag.imgShow = tag.img;
      tag.active = false;
    },
    handleToJump(type) {
      if (type) {
        window.open(enterEnterprise(type, this.local));
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.aside {
  position: fixed;
  right: 20px;
  top: 160px;
  z-index: 99;
  .box {
    width: 90px;
    height: 90px;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    background-color: #f2f2f2;
    text-align: center;
    box-shadow: 0px -1px 8px 0px rgba(153, 153, 153, 0.15);
    .mt_7 {
      margin-top: 7px;
    }
    .fc_C9C9C9 {
      color: #c9c9c9;
    }
    .fc_363636 {
      color: #363636;
    }
  }
}
.wx_image {
  width: 37px;
  height: 30px;
}
.code_image {
  width: 120px;
  height: 120px;
}
.top_image {
  width: 30px;
  height: 30px;
}
.pd_code {
  // padding: 18px 22px;
}
.wxlogo_image {
  width: 18px;
  height: 15px;
}
.ml_5 {
  margin-left: 5px;
}
.fc_07C160 {
  color: #07c160;
}
.fz_10 {
  font-size: 10px;
}
.lh_16 {
  line-height: 16px;
}
.company_image {
  width: 30px;
  height: 30px;
}
.fc_666 {
  color: #666666;
}
.fc_fff {
  color: #ffffff;
}
.bg_2C8EFF {
  background-color: #2c8eff !important;

  border: 1px solid #2c8eff !important;
}
</style>