<template>
  <div class="bg_FFFFFF ptb_60">
    <div class="flex justify-center">
      <div class="fixed_width flex align-center">
        <div class="fz_30 font_blod fc_2C8EFF">联系我们</div>
        <div class="fz_24 fc_E3E3E3 font_blod ml_10">Contact</div>
      </div>
    </div>

    <div class="flex mt_60 justify-center">
      <el-row :gutter="0" class="fixed_width">
        <el-col :span="10" v-if="info">
          <div class="fz_24 fc_747474 font_blod" v-if="info.purpose">
            <!-- 为你想的更多，为你做的更好！ -->
            {{ info.purpose }}
          </div>
          <div class="mt_30 flex fc_A2A1A1 fc_16">
            <div>
              <!-- 无锡市惠山经济开发区智慧路18号 -->
              <div class="mt_10" v-if="info.address">
                地址：{{ info.address }}
              </div>
              <div class="mt_10" v-if="info.addressCode">
                邮编：{{ info.addressCode }}
              </div>
              <!-- 0510-83592880 -->
              <div class="mt_10" v-if="info.phone">电话：{{ info.phone }}</div>
              <!-- 15052129327 -->
              <div class="mt_10" v-if="info.mobilePhone">
                手机：{{ info.mobilePhone }}
              </div>
              <!-- <div>传真：0510-88886666</div> -->
              <!-- xulan@wxhkhr.com -->
              <div class="mt_10" v-if="info.email">邮箱：{{ info.email }}</div>
            </div>
            <!-- <div class="ml_30">
              <div>手机：15052129327</div>
              <div>邮箱：xulan@wxhkhr.com</div>
            </div> -->
          </div>
          <div class="mt_40 flex">
            <!-- <div class="text-center">
              <img
                src="../../assets/images/home/subscription.jpg"
                class="code_img"
              />
              <div class="fc_747474 fz_20 font_blod mt_20">关注订阅号</div>
            </div> -->
            <div class="text-center">
              <img
                src="../../assets/images/home/service1.png"
                class="code_img"
              />
              <div class="fc_747474 fz_20 font_blod mt_20">关注服务号</div>
            </div>
          </div>
        </el-col>
        <el-col :span="14">
          <Map />
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import Map from "@/components/map/index";
import { getAboultUs } from "@/api/home";
export default {
  components: {
    Map,
  },
  data() {
    return {
      info: {},
    };
  },
  mounted() {
    if (sessionStorage.getItem("about_info")) {
      this.info = JSON.parse(sessionStorage.getItem("about_info"));
    } else {
      getAboultUs().then((res) => {
        this.info = res.data;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/styles/home.scss";
</style>
