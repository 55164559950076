<template>
  <div class="bg">
    <Header :activeName="activeName" />
    <PortalHome />
    <Aside />
    <Bottom />
  </div>
</template>

<script>
import Header from "@/components/header/index";
import {
  // getInfo,
  getAboultUs,
} from "@/api/home";
import PortalHome from "./portalHome";
import Aside from "@/components/aside/index";
import Bottom from "@/components/bottom/index";
export default {
  components: {
    Header,
    PortalHome,
    Aside,
    Bottom,
  },
  data() {
    return {
      activeName: "门户首页",
    };
  },
  mounted() {
    // if(sessionStorage.getItem('index_list')) {
    //   getInfo().then((res) => {
    //     sessionStorage.setItem('index_list', JSON.stringify(res.data));
    //     // res.data.list_spt.forEach((item) => {
    //     //   item.type = 'page';
    //     // });
    //     // this.tab_list = res.data.list_spt;
    //     // this.tab_list.unshift({
    //     //   id: 0,
    //     //   title: '门户首页'
    //     // });
    //     // res.data.list_nt.forEach((item) => {
    //     //   item.type = 'news';
    //     //   this.tab_list.push(item);
    //     // });
    //     // debugger
    //     // sessionStorage.setItem('tab_list', JSON.stringify(this.tab_list));
    //     // console.log(this.tab_list,'tablist')
    //   });
    // }
    getAboultUs().then((res) => {
      sessionStorage.setItem("about_info", JSON.stringify(res.data));
    });
  },
};
</script>
