<template>
  <div>
    <el-carousel class="banner">
      <el-carousel-item v-for="(item, index) in imgList" :key="index">
        <img :src="item.img" class="banner_img" />
      </el-carousel-item>
    </el-carousel>

    <div class="flex justify-center">
      <el-row class="fixed_width mt_40">
        <el-col :span="6" v-for="(item, index) in enterList" :key="index">
          <div
            @mouseleave="mouseLeave(item)"
            @mouseenter="mouseOver(item)"
            class="enter_box"
            :class="item.active == true ? 'bg_2C8EFF' : ''"
            @click="handleEnter(item.type)"
          >
            <div class="flex center align-center">
              <img
                :src="item.active == true ? item.activeImg : item.img"
                class="mr_10 enter_img"
              />
              <div
                class="fz_18"
                :class="item.active == true ? 'fc_FFFFFF' : ''"
              >
                {{ item.label }}
              </div>
              <img
                :src="item.active == true ? activeRightImg : rightImg"
                class="right_image ml_10"
              />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="flex mt_60 justify-center">
      <div class="fixed_width flex align-center">
        <div class="fz_30 font_blod fc_2C8EFF">我们的服务</div>
        <div class="fz_24 fc_E3E3E3 font_blod ml_10">Our Services</div>
      </div>
    </div>

    <div class="mt_30 flex justify-center">
      <div class="fixed_width50 flex">
        <div id="fixed_adv">
          <div class="card_pad">
            <div
              class="card mr_20"
              v-for="(item, index) in serviceList"
              :key="index"
              @click="handleToService(item.title)"
            >
              <img
                id="serviceList"
                :src="item.news.img ? item.news.img : noimg"
                class="title_img mg_20"
              />
              <div class="fz_18 fc_111C2F text-center">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center">
      <img src="../../assets/images/home/arrowLeft.png" @click="leftScroll" />
      <img
        src="../../assets/images/home/arrowRight.png"
        class="ml_20"
        @click="rightScroll"
      />
    </div>

    <div class="bg_news mt_60 ptb_60">
      <div class="flex justify-center">
        <div class="fixed_width flex justify-between">
          <div class="flex align-center">
            <div class="fz_30 font_blod fc_2C8EFF">新闻动态</div>
            <div class="fz_24 fc_E3E3E3 font_blod ml_10">News Center</div>
          </div>
          <div class="check">
            <el-button type="text" @click="handleToNews">查看更多</el-button>
          </div>
        </div>
      </div>

      <div class="mt_60 flex justify-center">
        <el-row :gutter="0" class="fixed_width cursor-pointer">
          <el-col :span="9">
            <div @click="handleToNewsDetail">
              <img :src="fullNews.img" class="cover_img" />
              <div class="mt_45">
                <div class="fc_5B5B5B fz_24 font_blod text-overflow1">
                  {{ fullNews.title }}
                </div>
                <div class="mt_30 flex">
                  <div class="text-center" style="width: 80px">
                    <div class="fc_5B5B5B fz_22 font_blod lh_27">
                      {{ fullNews.data }}
                    </div>
                    <div class="fc_D0D0D0 fz_22 font_blod lh_27">
                      {{ fullNews.year }}
                    </div>
                  </div>
                  <el-divider direction="vertical" content-position="center" />
                  <!-- <div
                    class="fc_5B5B5B fz_18 lh_27 text-overflow content_width"
                    v-html="fullNews.content"
                  ></div> -->
                  <div
                    class="fc_5B5B5B fz_18 lh_27 text-overflow content_width"
                  >
                    {{ fullNews.seoDescription }}
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="15">
            <div
              class="pl_30"
              v-for="(item, index) in simpNewList"
              :key="index"
              @click="handleToDetail(item)"
            >
              <el-divider />
              <div class="pl_30 mtb_42">
                <div class="fc_5B5B5B fz_24 font_blod text-overflow1">
                  {{ item.title }}
                </div>
                <div class="mt_30 flex">
                  <div class="text-center" style="width: 70px">
                    <div class="fc_5B5B5B fz_22 font_blod lh_27">
                      {{ item.data }}
                    </div>
                    <div class="fc_D0D0D0 fz_22 font_blod lh_27">
                      {{ item.year }}
                    </div>
                  </div>
                  <el-divider direction="vertical" content-position="center" />
                  <!-- <div
                    class="fc_5B5B5B fz_18 lh_27 text-overflow content_width mr_20"
                    v-html="item.content"
                  ></div> -->
                  <div
                    class="fc_5B5B5B fz_18 lh_27 text-overflow content_width mr_20"
                    v-html="item.seoDescription"
                  ></div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="flex mt_60 justify-center">
      <div class="flex justify-center">
        <div class="fixed_width flex justify-between">
          <div class="flex align-center">
            <div class="fz_30 font_blod fc_2C8EFF">我们的客户</div>
            <div class="fz_24 fc_E3E3E3 font_blod ml_10">Our Customers</div>
          </div>
          <div class="check">
            <el-button type="text" @click="handleToCoop">查看更多</el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex mt_60 justify-center">
      <div class="fixed_width50 align-center">
        <img
          v-for="(item, index) in customerList"
          :key="index"
          :src="item.img"
          class="custom_img mr_36 mb_16"
        />
      </div>
    </div>

    <div class="flex mt_60 justify-center mb_150">
      <div class="fixed_width align-center bg_FFFFFF">
        <div class="bg_about">
          <div class="flex">
            <!-- <img src="../../assets/images/home/about.jpg" class="about_img" /> -->
            <img :src="info.img" class="about_img" />
            <div class="about_content">
              <div class="fz_20 font_blod fc_747474">关于我们</div>
              <el-divider />
              <div class="fz_18 font_blod fc_E3E3E3">About Us</div>
              <!-- <div v-html="platformInfo.content" class="mt_40 text-overflow4"></div> -->
              <div
                v-html="info.detail"
                class="mt_40 text-overflow4 detail_info"
              ></div>
              <div class="btn_pst">
                <el-button @click="handleToAbout">了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg_151C2B ptb_60">
      <div class="flex mt_190 justify-center">
        <div class="fixed_width align-center">
          <div class="flex ml_40 mr_40">
            <div
              v-for="(item, index) in contentList"
              :key="index"
              class="datacontent text-center"
            >
              <div class="">
                <img
                  :src="item.img"
                  :class="index == '0' ? 'data1_img' : 'data_img'"
                />
                <div
                  class="fz_46 fc_FFFFFF font_blod mt_20"
                  style="height: 60px"
                >
                  {{ item.data }}
                </div>
                <div class="fz_18 fc_A2A1A1 mt_20">{{ item.content }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg_F8F8F8 ptb_60">
      <div class="flex justify-center">
        <div class="fixed_width flex align-center">
          <div class="fz_30 font_blod fc_2C8EFF">荣誉资质</div>
          <div class="fz_24 fc_E3E3E3 font_blod ml_10">Honors</div>
        </div>
      </div>
      <div class="flex mt_60 justify-center">
        <div class="fixed_width align-center">
          <div class="flex ml_40 mr_40">
            <div
              v-for="(item, index) in honorList"
              :key="index"
              class="datacontent text-center cursor-pointer"
              @click="handleToHonors"
            >
              <div class="">
                <img
                  :src="item.img"
                  :class="index == '2' ? 'honor1_img' : 'honor_img'"
                />
                <div class="fz_16 fc_A2A1A1 font_blod mt_35">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </div>
</template>

<script>
import { getInfo, getAboultUs } from "@/api/home";
import Contact from "@/components/contact/index";
import { enterEnterprise } from "@/utils";
import { routePortal } from "@/utils";
export default {
  components: {
    Contact,
  },
  data() {
    return {
      imgList: [],
      enterList: [
        {
          img: require("@/assets/images/home/company.png"),
          activeImg: require("@/assets/images/home/active_company.png"),
          label: "入驻企业专区",
          url: "",
          imgStyle: {
            width: "36px",
            height: "35px",
          },
          active: false,
          type: "company",
        },
        {
          img: require("@/assets/images/home/supply.png"),
          activeImg: require("@/assets/images/home/active_supply.png"),
          label: "供应商企业专区",
          url: "",
          imgStyle: {
            width: "36px",
            height: "35px",
          },
          active: false,
          type: "supply",
        },
        {
          img: require("@/assets/images/home/school.png"),
          activeImg: require("@/assets/images/home/active_school.png"),
          label: "学校/学院专区",
          url: "",
          imgStyle: {
            width: "38px",
            height: "35px",
          },
          active: false,
          type: "school",
        },
        {
          img: require("@/assets/images/home/third_company.png"),
          activeImg: require("@/assets/images/home/active_third_company.png"),
          label: "第三方服务企业专区",
          url: "",
          imgStyle: {
            width: "36px",
            height: "35px",
          },
          active: false,
          type: "third_company",
        },
      ],
      rightImg: require("@/assets/images/home/right.png"),
      activeRightImg: require("@/assets/images/home/activeRight.png"),
      maxClickNum: 0, // 最大点击次数
      lastLeft: 0, // 上次滑动距离
      clickNum: 0, // 点击次数
      serviceList: [],
      newsList: [],
      fullNews: "",
      simpNewList: [],
      customerList: [],
      platformInfo: "",
      contentList: [
        {
          img: require("@/assets/images/home/data1.png"),
          data: "3年",
          content: "专注园区招聘服务",
        },
        {
          img: require("@/assets/images/home/data2.png"),
          data: "150+",
          content: "服务企业",
        },
        {
          img: require("@/assets/images/home/data3.png"),
          data: "2000+",
          content: "为服务企业输送人才",
        },
        {
          img: require("@/assets/images/home/data4.png"),
          data: "40+",
          content: "服务企业覆盖行业",
        },
        {
          img: require("@/assets/images/home/data5.png"),
          data: "98%+",
          content: "客户满意度",
        },
      ],
      honorList: [
        {
          img: require("@/assets/images/home/honor1.png"),
          content: "信息安全管理体系认证",
        },
        {
          img: require("@/assets/images/home/honor2.png"),
          content: "信息安全等级保护（三级）",
        },
        {
          img: require("@/assets/images/home/honor3.png"),
          content: "省级高新技术企业研究开发中心",
        },
        {
          img: require("@/assets/images/home/honor4.png"),
          content: "ICP电信增值业务经营许可证",
        },
        {
          img: require("@/assets/images/home/honor5.png"),
          content: "人力资源服务许可证",
        },
      ],
      sptList: [],
      ntList: [],
      noimg: require("@/assets/images/home/noimg.jpg"),
      local: "",
      info: {},
    };
  },
  mounted() {
    this.getIndexInfo();
    if (sessionStorage.getItem("about_info")) {
      this.info = JSON.parse(sessionStorage.getItem("about_info"));
    } else {
      getAboultUs().then((res) => {
        this.info = res.data;
      });
    }
    this.local = window.location.host;
  },
  methods: {
    getIndexInfo() {
      getInfo().then((res) => {
        console.log(res);
        sessionStorage.setItem("index_list", JSON.stringify(res.data));
        this.newsList = res.data.list_news;

        this.newsList.forEach((item) => {
          item.data = item.createTime.substring(5, 10);
          item.year = item.createTime.substring(0, 4);
        });
        this.fullNews = this.newsList[0];
        this.simpNewList = this.newsList.filter((val, index) => {
          return index !== 0;
        });
        this.customerList = res.data.list_customer;
        if (res.data.platform) {
          this.platformInfo = res.data.platform;
        }
        this.views = res.data.visit;
        this.imgList = res.data.list_banner;
        // res.data.list_wm.forEach(item => {
        //   if(item.news != null) {
        //     this.serviceList.push(item)
        //   }
        // })
        this.serviceList = res.data.list_wm;
        this.sptList = res.data.list_spt;
        this.ntList = res.data.list_nt;
      });
    },
    leftScroll() {
      if (this.clickNum > 0) {
        // 获取当前元素宽度
        console.log(document.querySelectorAll(".card"));
        let width =
          document.querySelectorAll(".card")[this.clickNum - 1].offsetWidth;
        // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        console.log(document.getElementsByClassName("card_pad"));
        document.getElementsByClassName("card_pad")[0].style.marginLeft = `${
          this.lastLeft + width
        }px`;
        this.lastLeft = width + this.lastLeft;
        // 点击次数-3
        this.clickNum = this.clickNum - 1;
        // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
        if (this.clickNum < this.maxClickNum) {
          this.showRightIcon = true;
        }
      }
    },
    rightScroll() {
      // 如果点击次数小于数组长度-1时，执行左滑动效果。
      if (this.clickNum < this.serviceList.length - 1) {
        document.getElementById("fixed_adv").style.marginLeft = "25px";
        // 获取当前元素宽度
        let width =
          document.querySelectorAll(".card")[this.clickNum].offsetWidth;
        // 获取最后一个元素距离左侧的距离
        let lastItemOffsetLeft =
          document.getElementsByClassName("card")[this.serviceList.length - 1]
            .offsetLeft;
        // 获取可视区域宽度
        const lookWidth = document.getElementById("fixed_adv").clientWidth;
        // 如果最后一个元素距离左侧的距离大于可视区域的宽度，表示最后一个元素没有出现，执行滚动效果
        if (lastItemOffsetLeft > lookWidth - 140) {
          // 公示：滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          document.getElementsByClassName("card_pad")[0].style.marginLeft = `${
            -width + this.lastLeft
          }px`;
          this.lastLeft = -width + this.lastLeft;
          // 点击次数+3
          this.clickNum += 1;
          // 记录到最后一个元素出现在可视区域时，点击次数的最大值。用于后面点击左侧箭头时判断右侧箭头的显示
          this.maxClickNum = this.clickNum;
        }
        this.showRightIcon = lastItemOffsetLeft > lookWidth + width;
      }
    },
    mouseOver(tag) {
      tag.active = true;
    },
    // 移出
    mouseLeave(tag) {
      tag.active = false;
    },
    // 企业
    handleEnter(type) {
      window.open(enterEnterprise(type, this.local));
    },
    // 我们的服务
    handleToService(key) {
      const title = "我们的服务";
      this.ntList.forEach((item) => {
        if (item.title == title) {
          sessionStorage.setItem("info", JSON.stringify(item));
          this.$router.push(routePortal(title, key));
        }
      });
    },
    // 新闻资讯详情
    handleToNewsDetail() {
      this.$router.push({
        path: "./newsDetail",
        query: { id: this.fullNews.id },
      });
    },
    // 新闻资讯详情
    handleToDetail(item) {
      this.$router.push({
        path: "./newsDetail",
        query: { id: item.id },
      });
    },
    // 查看更多新闻资讯
    handleToNews() {
      const title = "新闻资讯";
      this.ntList.forEach((item) => {
        if (item.title == title) {
          sessionStorage.setItem("info", JSON.stringify(item));
          this.$router.push(routePortal(title));
        }
      });
    },
    // 查看更多我们的客户
    handleToCoop() {
      const title = "我们的合作";
      const key = "我们的客户";
      this.ntList.forEach((item) => {
        if (item.title == title) {
          sessionStorage.setItem("info", JSON.stringify(item));
          this.$router.push(routePortal(title, key));
        }
      });
    },
    // 关于我们了解详情
    handleToAbout() {
      const title = "关于我们";
      const key = "平台介绍";
      this.sptList.forEach((item) => {
        if (item.title == title) {
          sessionStorage.setItem("info", JSON.stringify(item));
          this.$router.push(routePortal(title, key));
        }
      });
    },
    // 荣誉资质
    handleToHonors() {
      const title = "关于我们";
      const key = "荣誉资质";
      this.sptList.forEach((item) => {
        if (item.title == title) {
          sessionStorage.setItem("info", JSON.stringify(item));
          this.$router.push(routePortal(title, key));
        }
      });
      // this.$router.push({
      //   path: "./honors",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/home.scss";
</style>
